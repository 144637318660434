import styles from './ItemInstanceInspector.module.css'

import React from 'react'

import { useWebSocket } from '../../../contexts/WebSocketContext'
import { useZoneState } from '../../../contexts/ZoneContext'
import { useSelectionDispatch, useSelectionState } from '../../../contexts/SelectionContext'
import Action from './Action'
import payloadForAction from '../../../util/PayloadBuilder'
import DialogExplorer from '../DialogExplorer/DialogExplorer'

function ItemInstanceInspector () {
  const webSocket = useWebSocket()
  const zoneState = useZoneState()
  const selectionState = useSelectionState()
  const selectionDispatch = useSelectionDispatch()
  const itemInstance = selectionState.selectedItemInstance

  function actionClicked (e, actionId) {
    e.preventDefault()
    const action = itemInstance.actions[actionId]
    const defaultPayload = payloadForAction(actionId, action, itemInstance, zoneState.zone)
    if (defaultPayload !== undefined) {
      webSocket.webSocket.send(JSON.stringify(defaultPayload))
    } else {
      selectionDispatch({
        type: 'selectItemInstanceAction',
        payload: actionId
      })
      switch (action.name) {
        case 'pickUpItemInstance': {
          selectionDispatch({
            type: 'enableItemInstanceZoneTargetMode'
          })
          break
        }
        case 'placeItemInstance': {
          selectionDispatch({
            type: 'enableItemInstanceInventoryTargetMode'
          })
          break
        }
      }
    }
  }

  return (
    <div className={styles.ItemInstanceInspector}>
      <h3>
        Selected Item: {itemInstance.name}
      </h3>
      <h4>Actions</h4>
      <ul className={styles.ItemInstanceInspectorList}>
        {Object.entries(itemInstance.actions)
          .filter((entry) => entry[1].alias)
          .map((entry) => {
            const actionId = entry[0]
            const action = entry[1]
            const performAction = (e) => actionClicked(e, actionId)
            return (
              <li key={actionId}>
                <Action action={action} performAction={performAction} />
              </li>
            )
          })}
      </ul>
      {itemInstance.dialogs && (
        <div>
          <h4>Dialog</h4>
          <DialogExplorer itemInstance={itemInstance} />
        </div>
      )}
    </div>
  )
}

export default ItemInstanceInspector
