import styles from './GoodSelector.module.css'

import React from 'react'

import { useGoodsCatalog } from '../../../contexts/GoodsCatalogContext'

function GoodSelector ({ selectedGoodId, selectGoodId }) {
  const goodsCatalog = useGoodsCatalog()

  return (
    <div className={styles.GoodSelector}>
      <select value={selectedGoodId} onChange={(e) => selectGoodId(e.target.value)}>
        <option value='' />
        {Object.entries(goodsCatalog).map((entry) => {
          const good = entry[1]
          return (
            <option key={good.PK} value={good.PK}>{good.name}</option>
          )
        })}
      </select>
    </div>
  )
}

export default GoodSelector
