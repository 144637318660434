import styles from './CreateItemInstanceTool.module.css'

import React, { useEffect } from 'react'

import { useImmerReducer } from 'use-immer'

import { useWebSocket } from '../../../contexts/WebSocketContext'

import ItemSelector from '../../Generic/ItemSelector/ItemSelector'
import InputRequirementBuilder from './InputRequirementBuilder'
import ReferenceRequirementBuilder from './ReferenceRequirementBuilder'

function itemInstanceReducer (draft, update) {
  switch (update.type) {
    case 'setItem': {
      draft.itemId = update.payload.itemId
      draft.actions = update.payload.actions
      return draft
    }
    case 'setRequirementValue': {
      draft.actions[update.payload.actionId].requirements[update.payload.requirementIndex].schema.const = update.payload.value
      return draft
    }
    default: { return draft }
  }
}

function CreateItemInstanceTool ({ itemInstance, itemInstanceActionId, createItemInstanceComplete }) {
  const webSocket = useWebSocket()
  const [itemInstanceState, itemInstanceDispatch] = useImmerReducer(itemInstanceReducer, {
    itemId: '',
    actions: {}
  })

  useEffect(() => {
    const listener = (message) => {
      const data = JSON.parse(message.data)
      for (const update of (data.updates || [])) {
        switch (update.event) {
          case 'itemInstanceAddedToInventory': {
            createItemInstanceComplete(update.payload.itemInstance)
            break
          }
          default: { break }
        }
      }
    }
    webSocket.webSocket.addEventListener('message', listener)
    return () => {
      webSocket.webSocket.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket, createItemInstanceComplete])

  function createItemInstance (e) {
    e.preventDefault()
    const requirements = Object.fromEntries(Object.entries(itemInstanceState.actions).map((entry) => {
      const actionId = entry[0]
      const action = entry[1]
      return [actionId, action.requirements]
    }))
    webSocket.webSocket.send(JSON.stringify({
      action: 'createItemInstance',
      via: {
        id: itemInstance.SK,
        contextId: itemInstance.PK,
        actionId: itemInstanceActionId
      },
      references: {
        item: {
          id: itemInstanceState.itemId
        }
      },
      inputs: {
        requirements
      }
    }))
  }

  function dismiss (e) {
    e.preventDefault()
    createItemInstanceComplete(false)
  }

  function selectItem (selectedItem) {
    if (!selectedItem) {
      itemInstanceDispatch({
        type: 'setItem',
        payload: {
          itemId: '',
          actions: {}
        }
      })
    } else {
      const actions = JSON.parse(JSON.stringify(selectedItem.actions))
      Object.entries(actions).forEach((entry) => {
        const action = entry[1]
        action.requirements = (action.requirements ?? []).filter((requirement) => {
          return requirement.schema?.const === undefined
        })
      })
      itemInstanceDispatch({
        type: 'setItem',
        payload: {
          itemId: selectedItem.PK,
          actions
        }
      })
    }
  }

  return (
    <div className={styles.CreateItemInstanceTool}>
      Create an Item Instance
      <ItemSelector
        selectedItemId={itemInstance.itemId}
        selectItem={selectItem}
      />
      {Object.entries(itemInstanceState.actions).map((entry) => {
        const actionId = entry[0]
        const action = entry[1]
        if (!action.requirements?.length) {
          return null
        }
        return (
          <div key={actionId}>
            <h4>{action.alias ?? action.name}</h4>
            {action.requirements.filter((requirement) => requirement.kind === 'input').map((requirement) => {
              return (
                <div key={requirement.property}>
                  <InputRequirementBuilder
                    requirement={requirement}
                    itemInstanceDispatch={itemInstanceDispatch}
                  />
                </div>
              )
            })}
            {action.requirements.filter((requirement) => requirement.kind === 'reference').map((requirement) => {
              return (
                <div key={`${requirement.name}.${requirement.property}`}>
                  <ReferenceRequirementBuilder
                    actionId={actionId}
                    requirementIndex={action.requirements.indexOf(requirement)}
                    requirement={requirement}
                    itemInstanceDispatch={itemInstanceDispatch}
                  />
                </div>
              )
            })}
          </div>
        )
      }).filter((_) => _)}
      <br />
      <button onClick={createItemInstance}>Create</button>
      <button onClick={dismiss}>Cancel</button>
    </div>
  )
}

export default CreateItemInstanceTool
