import React, { Dispatch, useContext, useEffect } from 'react'
import { useImmerReducer } from 'use-immer'
import { ItemInstance } from '../types'

import { useWebSocket } from './WebSocketContext'

const SelectionStateContext = React.createContext<undefined | SelectionState>(undefined)
const SelectionDispatchContext = React.createContext<undefined | Dispatch<SelectionAction>>(undefined)

const TargetMode = {
  DISABLED: 0,
  CHARACTER: 1,
  ITEM_INSTANCE_ZONE: 2,
  ITEM_INSTANCE_INVENTORY: 3
}

const initialState = {
  selectedCharacterId: undefined,
  selectedItemInstance: undefined,
  selectedItemInstanceActionId: undefined,

  targetMode: TargetMode.DISABLED,
  targetedCharacterId: undefined,
  targetedItemInstance: undefined
}

interface SelectionState {
  selectedCharacterId: undefined | string
  selectedItemInstance: undefined | ItemInstance
  selectedItemInstanceActionId: undefined | string

  targetMode: number
  targetedCharacterId: undefined | string
  targetedItemInstance: undefined | ItemInstance
}

enum SelectionActionType {
  Deselect = 'deselect',
  DeselectCharacter = 'deselectCharacter',
  DeselectItemInstance = 'deselectItemInstance',
  DeselectItemInstanceAction = 'deselectItemInstanceAction',
  DeselectNotInZone = 'deselectNotInZone',
  DisableTargetMode = 'disableTargetMode',
  EnableCharacterTargetMode = 'enableCharacterTargetMode',
  EnableItemInstanceInventoryTargetMode = 'enableItemInstanceInventoryTargetMode',
  EnableItemInstanceZoneTargetMode = 'enableItemInstanceZoneTargetMode',
  ProgressiveDeselect = 'progressiveDeselect',
  SelectCharacter = 'selectCharacter',
  SelectItemInstance = 'selectItemInstance',
  SelectItemInstanceAction = 'selectItemInstanceAction',
  SetItemInstanceActionCharges = 'setItemInstanceActionCharges'
}

interface SelectionActionDeselect {
  type: SelectionActionType.Deselect
}
interface SelectionActionDeselectCharacter {
  type: SelectionActionType.DeselectCharacter
  payload: string
}
interface SelectionActionDeselectItemInstance {
  type: SelectionActionType.DeselectItemInstance
  payload: string
}
interface SelectionActionDeselectItemInstanceAction {
  type: SelectionActionType.DeselectItemInstanceAction
}
interface SelectionActionDeselectNotInZone {
  type: SelectionActionType.DeselectNotInZone
  payload: string
}
interface SelectionActionDisableTargetMode {
  type: SelectionActionType.DisableTargetMode
}
interface SelectionActionEnableCharacterTargetMode {
  type: SelectionActionType.EnableCharacterTargetMode
}
interface SelectionActionEnableItemInstanceInventoryTargetMode {
  type: SelectionActionType.EnableItemInstanceInventoryTargetMode
}
interface SelectionActionEnableItemInstanceZoneTargetMode {
  type: SelectionActionType.EnableItemInstanceZoneTargetMode
}
interface SelectionActionProgressiveDeselect {
  type: SelectionActionType.ProgressiveDeselect
}
interface SelectionActionSelectCharacter {
  type: SelectionActionType.SelectCharacter
  payload: string
}
interface SelectionActionSelectItemInstance {
  type: SelectionActionType.SelectItemInstance
  payload: ItemInstance
}
interface SelectionActionSelectItemInstanceAction {
  type: SelectionActionType.SelectItemInstanceAction
  payload: string
}
interface SelectionActionSetItemInstanceActionCharges {
  type: SelectionActionType.SetItemInstanceActionCharges
  payload: {
    itemInstanceId: string
    itemActionId: string
    charges: number
  }
}

interface SelectionActionDisableTargetMode {
  type: SelectionActionType.DisableTargetMode
}

type SelectionAction =
  SelectionActionDeselect |
  SelectionActionDeselectCharacter |
  SelectionActionDeselectItemInstance |
  SelectionActionDeselectItemInstanceAction |
  SelectionActionDeselectNotInZone |
  SelectionActionDisableTargetMode |
  SelectionActionEnableCharacterTargetMode |
  SelectionActionEnableItemInstanceInventoryTargetMode |
  SelectionActionEnableItemInstanceZoneTargetMode |
  SelectionActionProgressiveDeselect |
  SelectionActionSelectCharacter |
  SelectionActionSelectItemInstance |
  SelectionActionSelectItemInstanceAction |
  SelectionActionSetItemInstanceActionCharges

function selectionReducer (draft: SelectionState, update: SelectionAction): SelectionState {
  switch (update.type) {
    case SelectionActionType.DisableTargetMode: {
      draft.targetedCharacterId = undefined
      draft.targetedItemInstance = undefined
      draft.targetMode = TargetMode.DISABLED
      return draft
    }
    case SelectionActionType.EnableCharacterTargetMode: {
      draft.targetedCharacterId = undefined
      draft.targetMode = TargetMode.CHARACTER
      return draft
    }
    case SelectionActionType.EnableItemInstanceZoneTargetMode: {
      draft.targetedItemInstance = undefined
      draft.targetMode = TargetMode.ITEM_INSTANCE_ZONE
      return draft
    }
    case SelectionActionType.EnableItemInstanceInventoryTargetMode: {
      draft.targetedItemInstance = undefined
      draft.targetMode = TargetMode.ITEM_INSTANCE_INVENTORY
      return draft
    }
    case SelectionActionType.SelectCharacter: {
      if (draft.targetMode === TargetMode.CHARACTER) {
        draft.targetedCharacterId = update.payload
      } else {
        return {
          ...initialState,
          selectedCharacterId: update.payload
        }
      }
      return draft
    }
    case SelectionActionType.SelectItemInstance: {
      const itemInstance = update.payload
      if (draft.targetMode === TargetMode.ITEM_INSTANCE_ZONE && itemInstance.PK.indexOf('zone#') === 0) {
        draft.targetedItemInstance = itemInstance
      } else if (draft.targetMode === TargetMode.ITEM_INSTANCE_INVENTORY && itemInstance.PK.indexOf('character#') === 0) {
        draft.targetedItemInstance = itemInstance
      } else {
        return {
          ...initialState,
          selectedItemInstance: itemInstance
        }
      }
      return draft
    }
    case SelectionActionType.SelectItemInstanceAction: {
      draft.selectedItemInstanceActionId = update.payload
      draft.targetMode = TargetMode.DISABLED
      return draft
    }
    case SelectionActionType.ProgressiveDeselect: {
      if (draft.targetMode !== TargetMode.DISABLED) {
        draft.targetedCharacterId = undefined
        draft.targetedItemInstance = undefined
        draft.targetMode = TargetMode.DISABLED
      } else {
        return initialState
      }
      return draft
    }
    case SelectionActionType.Deselect: {
      return initialState
    }
    case SelectionActionType.DeselectCharacter: {
      if (draft.targetedCharacterId === update.payload) {
        draft.targetedCharacterId = undefined
      }
      if (draft.selectedCharacterId === update.payload) {
        draft.selectedCharacterId = undefined
      }
      return draft
    }
    case SelectionActionType.DeselectItemInstance: {
      if (draft.targetedItemInstance?.SK === update.payload) {
        draft.targetedItemInstance = undefined
      }
      if (draft.selectedItemInstance?.SK === update.payload) {
        draft.selectedItemInstance = undefined
        draft.selectedItemInstanceActionId = undefined
      }
      return draft
    }
    case SelectionActionType.DeselectItemInstanceAction: {
      draft.selectedItemInstanceActionId = undefined
      return draft
    }
    case SelectionActionType.DeselectNotInZone: {
      if (draft.selectedItemInstance?.PK.indexOf('zone#') === 0) {
        return initialState
      }
      if (draft.selectedCharacterId !== undefined) {
        return initialState
      }
      return draft
    }
    case SelectionActionType.SetItemInstanceActionCharges: {
      if (draft.selectedItemInstance !== undefined && draft.selectedItemInstance.SK === update.payload.itemInstanceId) {
        draft.selectedItemInstance.actions[update.payload.itemActionId].charges = update.payload.charges
      }
      return draft
    }
    default: { return draft }
  }
}

function SelectionProvider ({ children }: { children: React.ReactNode }): JSX.Element {
  const webSocket = useWebSocket()
  const [state, dispatch] = useImmerReducer<SelectionState, SelectionAction>(selectionReducer, { ...initialState })

  useEffect(() => {
    function handleKeyDown (e: KeyboardEvent): void {
      if (e.key === 'Escape') {
        dispatch({
          type: SelectionActionType.ProgressiveDeselect
        })
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [dispatch])

  useEffect(() => {
    const listener = (message: MessageEvent<string>): void => {
      const data = JSON.parse(message.data)
      for (const update of (data.updates ?? [])) {
        switch (update.event) {
          case 'zoneReturned': {
            const zone = update.payload.zone
            dispatch({
              type: SelectionActionType.DeselectNotInZone,
              payload: zone.PK
            })
            break
          }
          // TODO: Some sort of "patcher" functions that you can pass updates to and some part of your draft

          // Is it smart to have this thing be kinda smart? Maybe better than having too many components dispatch to it?
          case 'itemInstanceActionChargesChanged': {
            dispatch({
              type: SelectionActionType.SetItemInstanceActionCharges,
              payload: {
                itemInstanceId: update.payload.itemInstanceId,
                itemActionId: update.payload.itemActionId,
                charges: update.payload.charges
              }
            })
            break
          }
          default: { break }
        }
      }
    }
    webSocket.webSocket?.addEventListener('message', listener)
    return () => {
      webSocket.webSocket?.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket, dispatch])

  return (
    <SelectionDispatchContext.Provider value={dispatch}>
      <SelectionStateContext.Provider value={state}>
        {children}
      </SelectionStateContext.Provider>
    </SelectionDispatchContext.Provider>
  )
}

function useSelectionState (): SelectionState {
  const context = useContext(SelectionStateContext)
  if (context === undefined) {
    throw new Error('useSelectionState must be called within a SelectionProvider')
  }
  return context
}

function useSelectionDispatch (): Dispatch<SelectionAction> {
  const context = useContext(SelectionDispatchContext)
  if (context === undefined) {
    throw new Error('useSelectionDispatch must be called within a SelectionProvider')
  }
  return context
}

export { TargetMode, SelectionProvider, useSelectionState, useSelectionDispatch }
