import styles from './CreateDialogTool.module.css'

import React, { useEffect } from 'react'
import { useImmerReducer } from 'use-immer'

import { useRequestWithCompletion } from '../../../contexts/WebSocketContext'
import DialogBuilder from './DialogBuilder'

function dialogsReducer (draft, update) {
  switch (update.type) {
    case 'addDialog': {
      const maxId = Object.keys(draft.dialogs).reduce((highest, current) => {
        current = parseInt(current)
        return current > highest ? current : highest
      }, 0)
      draft.dialogs[maxId + 1] = {
        hidden: true,
        prompt: '',
        text: {
          template: ''
        },
        responses: {}
      }
      return draft
    }

    case 'removeDialog': {
      delete draft.dialogs[update.payload]
      return draft
    }

    case 'setHiddenDialog': {
      draft.dialogs[update.payload.dialogId].hidden = update.payload.value
      return draft
    }

    case 'setDialogPrompt': {
      draft.dialogs[update.payload.dialogId].prompt = update.payload.value
      draft.dialogs[update.payload.dialogId].hidden = false
      return draft
    }

    case 'setDialogText': {
      draft.dialogs[update.payload.dialogId].text.template = update.payload.value
      return draft
    }

    case 'addDialogResponse': {
      const maxId = Object.keys(draft.dialogs[update.payload.dialogId].responses).reduce((highest, current) => {
        current = parseInt(current)
        return current > highest ? current : highest
      }, 0)

      draft.dialogs[update.payload.dialogId].responses[maxId + 1] = {
        text: {
          template: ''
        },
        dialog: ''
      }
      return draft
    }

    case 'removeDialogResponse': {
      delete draft.dialogs[update.payload.dialogId].responses[update.payload.responseId]
      return draft
    }

    case 'setResponseText': {
      draft.dialogs[update.payload.dialogId]
        .responses[update.payload.responseId]
        .text.template = update.payload.value
      return draft
    }

    case 'selectResponseNextDialog': {
      draft.dialogs[update.payload.dialogId]
        .responses[update.payload.responseId]
        .dialog = update.payload.value
      return draft
    }
  }
}

function CreateDialogTool ({ itemInstance, itemInstanceActionId, createDialogComplete }) {
  const [dialogsState, dialogsDispatch] = useImmerReducer(dialogsReducer, { dialogs: {} })
  const [isRequestComplete, makeRequest] = useRequestWithCompletion()

  useEffect(() => {
    if (isRequestComplete) {
      createDialogComplete(true)
    }
  }, [isRequestComplete, createDialogComplete])

  function createDialog (e) {
    e.preventDefault()
    const dialogs = JSON.parse(JSON.stringify(dialogsState.dialogs))
    for (const [, dialog] of Object.entries(dialogs)) {
      if (dialog.hidden) {
        delete dialog.prompt
      }
      for (const [, response] of Object.entries(dialog.responses)) {
        if (response.dialog === '') {
          delete response.dialog
        }
      }
      if (Object.keys(dialog.responses).length === 0) {
        delete dialog.responses
      }
    }
    makeRequest({
      action: 'createDialog',
      via: {
        id: itemInstance.SK,
        contextId: itemInstance.PK,
        actionId: itemInstanceActionId
      },
      inputs: {
        dialogs
      }
    })
  }

  function dismiss (e) {
    e.preventDefault()
    createDialogComplete(false)
  }

  function addDialog (e) {
    e.preventDefault()
    dialogsDispatch({ type: 'addDialog' })
  }

  return (
    <div className={styles.CreateDialogTool}>
      Create a Dialog
      <form>
        <div>
          <button onClick={addDialog}>+</button><label>Dialogs</label>
          {Object.entries(dialogsState.dialogs).map(([dialogId, dialog]) => {
            return (
              <DialogBuilder key={dialogId} dialogId={dialogId} dialog={dialog} dialogsDispatch={dialogsDispatch} allDialogs={dialogsState.dialogs} />
            )
          })}
        </div>
        <br />
        <button onClick={createDialog}>Create</button>
        <button onClick={dismiss}>Cancel</button>
      </form>
    </div>
  )
}

export default CreateDialogTool
