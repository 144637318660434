import styles from './App.module.css'

import React, { useState } from 'react'
import Game from '../Game/Game'

import { AuthProvider } from '../../contexts/AuthContext'
import { WebSocketProvider } from '../../contexts/WebSocketContext'

const showRerender = false

function App (): JSX.Element {
  const [, refresh] = useState({})

  return (
    <div className={styles.App}>
      {showRerender &&
        <button onClick={() => refresh({})}>Re-Render</button>}
      <AuthProvider>
        <WebSocketProvider>
          <Game />
        </WebSocketProvider>
      </AuthProvider>
    </div>
  )
}

export default App
