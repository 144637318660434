import styles from './ItemInstanceSelector.module.css'

import React from 'react'

function ItemInstanceSelector ({ selectedItemInstance, selectItemInstance }) {
  return (
    <div className={styles.ItemInstanceSelector}>
      <select value={selectedItemInstance} onChange={(e) => selectItemInstance(e.target.value)}>
        <option value='' />
        <option value='self'>This instance</option>
      </select>
    </div>
  )
}

export default ItemInstanceSelector
