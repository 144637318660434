import styles from './ItemSelector.module.css'

import React, { useEffect, useState } from 'react'
import { useWebSocket } from '../../../contexts/WebSocketContext'
import { useSelectionState } from '../../../contexts/SelectionContext'

function ItemSelector ({ selectedItemId, selectItem }) {
  const webSocket = useWebSocket()
  const [items, setItems] = useState([])
  const [itemSearchName, setItemSearchName] = useState('')
  const selectionState = useSelectionState()

  useEffect(() => {
    const listener = (message) => {
      const data = JSON.parse(message.data)
      for (const update of (data.updates || [])) {
        switch (update.event) {
          case 'itemsReturned': {
            setItems(update.payload.items)
            break
          }
          default: { break }
        }
      }
    }
    webSocket.webSocket.addEventListener('message', listener)
    return () => {
      webSocket.webSocket.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket])

  function searchItems (e) {
    e.preventDefault()

    const searchItemsActionEntry = Object.entries(selectionState.selectedItemInstance.actions).find((entry) => {
      return entry[1].name === 'searchItems'
    })
    if (!searchItemsActionEntry) {
      alert("You can't search for items")
      return
    }
    const request = {
      action: 'searchItems',
      inputs: {},
      via: {
        id: selectionState.selectedItemInstance.SK,
        contextId: selectionState.selectedItemInstance.PK,
        actionId: searchItemsActionEntry[0]
      }
    }
    if (itemSearchName) {
      request.inputs.name = itemSearchName
    }
    webSocket.webSocket.send(JSON.stringify(request))
  }

  function selectItemId (e) {
    const itemId = e.target.value
    const item = items.find((item) => item.PK === itemId)
    selectItem(item)
  }

  return (
    <div className={styles.ItemSelector}>
      <div>
        <label>
          Search for an item:
          <input type='text' value={itemSearchName} onChange={(e) => setItemSearchName(e.target.value)} />
          <button onClick={searchItems}>Search</button>
        </label>
      </div>
      Items:
      <select value={selectedItemId} onChange={selectItemId}>
        <option value=''>{items.length || '0'} Items</option>
        {items.map((availableItem) => {
          return (
            <option key={availableItem.PK} value={availableItem.PK}>{availableItem.name}</option>
          )
        })}
      </select>
    </div>
  )
}

export default ItemSelector
