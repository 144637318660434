import styles from './ActionBuilder.module.css'

import React from 'react'

import { useGoodsCatalog } from '../../../contexts/GoodsCatalogContext'
import { useActionsCatalog } from '../../../contexts/ActionsCatalogContext'
import QuantitySelector from '../../Generic/QuantitySelector/QuantitySelector'
import GoodSelector from '../../Generic/GoodSelector/GoodSelector'
import ItemSelector from '../../Generic/ItemSelector/ItemSelector'
import ZoneSelector from '../../Generic/ZoneSelector/ZoneSelector'
import ItemInstanceSelector from '../../Generic/ItemInstanceSelector/ItemInstanceSelector'

function ActionBuilder ({ actionId, action, itemDispatch }) {
  const goodsCatalog = useGoodsCatalog()
  const actionsCatalog = useActionsCatalog()

  function removeAction (e, actionId) {
    e.preventDefault()
    itemDispatch({ type: 'removeAction', payload: actionId })
  }

  function setActionName (e, actionId) {
    itemDispatch({ type: 'setActionName', payload: { actionId, name: e.target.value } })
    itemDispatch({ type: 'setActionRequirements', payload: { actionId, requirements: actionsCatalog[e.target.value].requirements } })
  }

  function setActionAlias (e, actionId) {
    itemDispatch({ type: 'setActionProperty', payload: { actionId, property: 'alias', value: e.target.value } })
  }

  function setActionCharges (actionId, charges) {
    itemDispatch({ type: 'setActionProperty', payload: { actionId, property: 'charges', value: charges } })
  }

  function addCost (e, actionId) {
    e.preventDefault()
    itemDispatch({ type: 'addCost', payload: actionId })
  }

  function removeCost (e, actionId, costIndex) {
    e.preventDefault()
    itemDispatch({ type: 'removeCost', payload: { actionId, costIndex } })
  }

  function setCostId (e, actionId, costIndex) {
    itemDispatch({ type: 'setCostProperty', payload: { actionId, costIndex, property: 'id', value: e.target.value } })
  }

  function setCostQuantity (actionId, costIndex, costQuantity) {
    itemDispatch({ type: 'setCostProperty', payload: { actionId, costIndex, property: 'quantity', value: costQuantity } })
  }

  function setRequirementExpectedBy (e, actionId, requirementIndex) {
    itemDispatch({ type: 'setRequirementProperty', payload: { actionId, requirementIndex, property: 'expectedBy', value: e.target.value } })
  }

  function selectorForRequirement (actionId, requirementIndex) {
    const requirement = action.requirements[requirementIndex]
    if (requirement.kind === 'reference') {
      switch (requirement.type) {
        case 'good': {
          return (
            <GoodSelector
              selectedGoodId={requirement.schema.const}
              selectGoodId={(goodId) => itemDispatch({
                type: 'setRequirementSchemaProperty',
                payload: { actionId, requirementIndex, property: 'const', value: goodId }
              })}
            />
          )
        }
        case 'item': {
          return (
            <ItemSelector
              selectedItemId={requirement.value}
              selectItem={(item) => itemDispatch({
                type: 'setRequirementSchemaProperty',
                payload: { actionId, requirementIndex, property: 'const', value: item.PK }
              })}
            />
          )
        }
        case 'itemInstance': {
          return (
            <ItemInstanceSelector
              selectedItemInstance={requirement.value}
              selectItemInstance={(itemInstance) => itemDispatch({
                type: 'setRequirementSchemaProperty',
                payload: { actionId, requirementIndex, property: 'const', value: itemInstance }
              })}
            />
          )
        }
        case 'zone': {
          return (
            <ZoneSelector
              selectedZoneId={requirement.schema.const}
              selectZoneId={(zoneId) => itemDispatch({
                type: 'setRequirementSchemaProperty',
                payload: { actionId, requirementIndex, property: 'const', value: zoneId }
              })}
            />
          )
        }
        default: break
      }
    } else if (requirement.kind === 'input') {
      switch (requirement.schema.type) {
        case 'integer': {
          return (
            <QuantitySelector
              quantity={requirement.schema.const}
              setQuantity={(quantity) => itemDispatch({
                type: 'setRequirementSchemaProperty',
                payload: { actionId, requirementIndex, property: 'const', value: quantity }
              })}
            />
          )
        }
        default: break
      }
    }
  }

  return (
    <div className={styles.ActionBuilder}>
      <div>
        <button onClick={(e) => removeAction(e, actionId)}>-</button>
        <label>
          Action Name:
          <select value={action.name} onChange={(e) => setActionName(e, actionId)}>
            <option value='' />
            {Object.entries(actionsCatalog).map((entry) => {
              const action = entry[1]
              return (
                <option key={action.name} value={action.name}>{action.name}</option>
              )
            })}
          </select>
        </label>
        <label>
          Alias:
          <input type='text' value={action.alias} onChange={(e) => setActionAlias(e, actionId)} />
        </label>
        <label>
          Charges:
          <QuantitySelector quantity={action.charges} setQuantity={(charges) => setActionCharges(actionId, charges)} allowUnlimited />
        </label>
      </div>
      <div>
        {action.requirements.map((requirement, index) => {
          return (
            <div key={index}>
              <div>Select {requirement.name ?? requirement.property}</div>
              <select value={requirement.expectedBy} onChange={(e) => setRequirementExpectedBy(e, actionId, index)}>
                <option value='itemCreation'>Now</option>
                <option value='itemInstantiation'>When Item Instance Created</option>
                <option value='actionUse'>When Action Used</option>
              </select>
              {requirement.expectedBy === 'itemCreation' && selectorForRequirement(actionId, index)}
            </div>
          )
        })}
      </div>
      <div>
        <button onClick={(e) => addCost(e, actionId)}>+</button>
        Costs
        {action.costs.map((cost, costIndex) => {
          return (
            <div key={costIndex}>
              <button onClick={(e) => removeCost(e, actionId, costIndex)}>-</button>
              <label>
                Good:
                <select value={cost.id} onChange={(e) => setCostId(e, actionId, costIndex)}>
                  <option value='' />
                  {Object.entries(goodsCatalog).map((entry) => {
                    const good = entry[1]
                    return (
                      <option key={good.PK} value={good.PK}>{good.name}</option>
                    )
                  })}
                </select>
              </label>
              <label>
                Quantity:
                <QuantitySelector quantity={cost.quantity} setQuantity={(quantity) => setCostQuantity(actionId, costIndex, quantity)} />
              </label>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ActionBuilder
