import styles from './Chat.module.css'

import React, { useEffect, useReducer, useRef } from 'react'

import { useWebSocket } from '../../contexts/WebSocketContext'

interface ChatMessage {
  id: number
  sender: string
  message: string
}

interface ChatState {
  messages: ChatMessage[]
}

enum ChatActionType {
  AppendMessage = 'appendMessage',
}

interface ChatActionAppendMessage {
  type: ChatActionType.AppendMessage
  payload: ChatMessage
}

type ChatAction = ChatActionAppendMessage

function messagesReducer (state: ChatState, action: ChatAction): ChatState {
  switch (action.type) {
    case ChatActionType.AppendMessage: {
      const date = new Date()
      return {
        ...state,
        messages: [
          ...state.messages,
          {
            id: action.payload.id,
            message: `${date.toLocaleTimeString()} - ${action.payload.sender}: ${action.payload.message}`,
            sender: action.payload.sender
          }
        ]
      }
    }
    default: { return state }
  }
}

function Chat (): JSX.Element {
  const [state, dispatch] = useReducer(messagesReducer, { messages: [] })
  const webSocket = useWebSocket()
  const messageList = useRef<HTMLDivElement>(null)

  function yo (e: React.MouseEvent<HTMLElement>): void {
    e.preventDefault()

    webSocket.webSocket?.send(JSON.stringify({ action: 'sendMessage' }))
  }

  useEffect(() => {
    const listener = (message: MessageEvent<string>): void => {
      const data = JSON.parse(message.data)
      if (data.message !== undefined) {
        dispatch({
          type: ChatActionType.AppendMessage,
          payload: {
            id: (new Date()).getTime(),
            sender: 'System',
            message: data.message
          }
        })
        if (messageList.current !== null) {
          messageList.current.scrollTop = 100
        }
      }
      for (const update of (data.updates ?? [])) {
        switch (update.event) {
          case 'messageReceived': {
            update.type = 'appendMessage'
            dispatch(update)
            if (messageList.current !== null) {
              messageList.current.scrollTop = 100
            }
            break
          }
          default: { break }
        }
      }
    }
    webSocket.webSocket?.addEventListener('message', listener)
    return () => {
      webSocket.webSocket?.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket])

  return (
    <div className={styles.Chat}>
      <div className={styles.MessageList} ref={messageList}>
        {state.messages.map((message) =>
          <div key={message.id}><span>{message.message}</span></div>
        )}
      </div>
      <div className={styles.Input}>
        <button onClick={yo} className={styles.Button}>Yo!</button>
      </div>
    </div>
  )
}

export default Chat
