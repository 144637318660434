import React from 'react'

import styles from './QuantitySelector.module.css'

function QuantitySelector ({ quantity, setQuantity, allowUnlimited }) {
  function _setQuantity (e, quantity) {
    e.preventDefault()
    setQuantity(parseInt(quantity))
  }

  const min = allowUnlimited ? -1 : 0

  return (
    <div className={styles.QuantitySelector}>
      <button disabled={quantity <= min} onClick={(e) => _setQuantity(e, Math.max(min, quantity - 5))}>--</button>
      <button disabled={quantity <= min} onClick={(e) => _setQuantity(e, quantity - 1)}>-</button>
      {allowUnlimited && quantity === -1 ? 'Unlimited' : quantity}
      <button onClick={(e) => _setQuantity(e, quantity + 1)}>+</button>
      <button onClick={(e) => _setQuantity(e, quantity + 5)}>++</button>
    </div>
  )
}

export default QuantitySelector
