import styles from './DialogExplorer.module.css'

import React, { useEffect } from 'react'
import { useImmer } from 'use-immer'

function DialogExplorer ({ itemInstance }) {
  const [dialogStack, setDialogStack] = useImmer([])
  const dialogs = itemInstance.dialogs ?? {}

  useEffect(() => {
    setDialogStack([])
  }, [setDialogStack, itemInstance.SK])

  function appendDialog (dialogId, responseId) {
    setDialogStack((draft) => {
      if (responseId) {
        draft.push({ dialogId, responseId })
        const nextDialogId = dialogs[dialogId].responses[responseId]?.dialog
        if (nextDialogId) {
          draft.push({ dialogId: nextDialogId })
        } else {
          draft.push({})
        }
      } else {
        draft.push({ dialogId })
      }
      return draft
    })
  }

  function startOver () {
    setDialogStack([])
  }

  if (dialogStack.length === 0) {
    return (
      <div className={styles.DialogExplorer}>
        <ul className={styles.ItemInstanceInspectorList}>
          {Object.entries(dialogs || {})
            .filter(([, dialog]) => !!dialog.prompt)
            .map(([dialogId, dialog]) => {
              return (
                <button key={dialogId} onClick={() => appendDialog(dialogId)}>{dialog.prompt}</button>
              )
            })}
        </ul>
      </div>
    )
  } else {
    const latestDialog = dialogStack[dialogStack.length - 1]
    const latestResponses = latestDialog.dialogId ? Object.entries(dialogs[latestDialog.dialogId]?.responses || {}) : []
    return (
      <div className={styles.DialogExplorer}>
        {dialogStack.flatMap(({ dialogId, responseId }) => {
          const utterances = []
          if (responseId && dialogs[dialogId]?.responses[responseId]) {
            utterances.push(<span key={responseId} className={styles.MyDialog}>{dialogs[dialogId].responses[responseId].text.template}</span>)
          } else if (dialogId && dialogs[dialogId]) {
            if (dialogs[dialogId].prompt) {
              utterances.push(
                <span key={`${dialogId}-prompt`} className={styles.MyDialog}>{dialogs[dialogId].prompt}</span>
              )
            }
            utterances.push(<span key={dialogId} className={styles.TheirDialog}>{dialogs[dialogId].text.template}</span>)
          }
          return utterances
        })}
        <div>
          {latestResponses.map(([responseId, response]) => {
            return (
              <button key={responseId} onClick={() => appendDialog(latestDialog.dialogId, responseId)}>{response.text.template}</button>
            )
          })}
          {latestResponses.length === 0 && (
            <button onClick={startOver}>Start Over</button>
          )}
        </div>
      </div>
    )
  }
}

export default DialogExplorer
