import styles from './CharacterInspector.module.css'

import React, { useEffect, useState } from 'react'

import { useWebSocket } from '../../../contexts/WebSocketContext'
import { useSelectionState } from '../../../contexts/SelectionContext'

function CharacterInspector () {
  const webSocket = useWebSocket()
  const [character, setCharacter] = useState()
  const selectionState = useSelectionState()

  useEffect(() => {
    const listener = (message) => {
      const data = JSON.parse(message.data)
      for (const update of (data.updates || [])) {
        switch (update.event) {
          case 'characterCreated':
          case 'characterReturned': {
            const character = update.payload.character
            if (character.PK !== selectionState.selectedCharacterId) { return }
            setCharacter(character)
            break
          }
          default: { break }
        }
      }
    }
    webSocket.webSocket.addEventListener('message', listener)

    webSocket.webSocket.send(JSON.stringify({
      action: 'getCharacter',
      references: {
        character: {
          id: selectionState.selectedCharacterId
        }
      }
    }))

    return () => {
      webSocket.webSocket.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket, selectionState.selectedCharacterId])

  return (
    <div className={styles.CharacterInspector}>
      {character &&
        <h4>
          Selected Character: {character.PK}
        </h4>}
    </div>
  )
}

export default CharacterInspector
