import React, { useCallback, useEffect } from 'react'

import styles from './Inspector.module.css'

import { useWebSocket } from '../../contexts/WebSocketContext'

import CharacterInspector from './CharacterInspector/CharacterInspector'
import ItemInstanceInspector from './ItemInstanceInspector/ItemInstanceInspector'
import { useSelectionDispatch, useSelectionState } from '../../contexts/SelectionContext'

import CreateDialogTool from '../ActionTools/CreateDialogTool/CreateDialogTool'
import CreateGoodTool from '../ActionTools/CreateGoodTool/CreateGoodTool'
import CreateItemInstanceTool from '../ActionTools/CreateItemInstanceTool/CreateItemInstanceTool'
import CreateItemTool from '../ActionTools/CreateItemTool/CreateItemTool'
import CreateZoneTool from '../ActionTools/CreateZoneTool/CreateZoneTool'
import EnterZoneTool from '../ActionTools/EnterZoneTool/EnterZoneTool'
import PickUpItemInstanceTool from '../ActionTools/PickUpItemInstanceTool/PickUpItemInstanceTool'
import PlaceItemInstanceTool from '../ActionTools/PlaceItemInstanceTool/PlaceItemInstanceTool'

function Inspector () {
  const webSocket = useWebSocket()
  const selectionState = useSelectionState()
  const selectionDispatch = useSelectionDispatch()

  useEffect(() => {
    const listener = (message) => {
      const data = JSON.parse(message.data)
      for (const update of (data.updates || [])) {
        switch (update.event) {
          case 'characterLeftZone': {
            selectionDispatch({
              type: 'deselectCharacter',
              payload: update.payload.characterId
            })
            break
          }
          case 'itemInstanceRemovedFromZone':
          case 'itemInstanceRemovedFromInventory': {
            selectionDispatch({
              type: 'deselectItemInstance',
              payload: update.payload.itemInstanceId
            })
            break
          }
          default: { break }
        }
      }
    }
    webSocket.webSocket.addEventListener('message', listener)
    return () => {
      webSocket.webSocket.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket, selectionDispatch])

  const deselectItemInstanceAction = useCallback(() => {
    selectionDispatch({
      type: 'deselectItemInstanceAction'
    })
  }, [selectionDispatch])
  const selectedItemInstance = selectionState.selectedItemInstance
  const selectedItemInstanceActionId = selectionState.selectedItemInstanceActionId
  const selectedItemInstanceAction = selectedItemInstance?.actions[selectedItemInstanceActionId]
  let tool
  switch (selectedItemInstanceAction?.name) {
    case 'createDialog': {
      tool = <CreateDialogTool itemInstance={selectedItemInstance} itemInstanceActionId={selectedItemInstanceActionId} createDialogComplete={deselectItemInstanceAction} />
      break
    }
    case 'createGood': {
      tool = <CreateGoodTool itemInstance={selectedItemInstance} itemInstanceActionId={selectedItemInstanceActionId} createGoodComplete={deselectItemInstanceAction} />
      break
    }
    case 'createItemInstance': {
      tool = <CreateItemInstanceTool itemInstance={selectedItemInstance} itemInstanceActionId={selectedItemInstanceActionId} createItemInstanceComplete={deselectItemInstanceAction} />
      break
    }
    case 'createItem': {
      tool = <CreateItemTool itemInstance={selectedItemInstance} itemInstanceActionId={selectedItemInstanceActionId} createItemComplete={deselectItemInstanceAction} />
      break
    }
    case 'createZone': {
      tool = <CreateZoneTool itemInstance={selectedItemInstance} itemInstanceActionId={selectedItemInstanceActionId} createZoneComplete={deselectItemInstanceAction} />
      break
    }
    case 'enterZone': {
      tool = <EnterZoneTool itemInstance={selectedItemInstance} itemInstanceActionId={selectedItemInstanceActionId} enterZoneComplete={deselectItemInstanceAction} />
      break
    }
    case 'pickUpItemInstance': {
      tool = <PickUpItemInstanceTool itemInstance={selectedItemInstance} itemInstanceActionId={selectedItemInstanceActionId} pickUpItemInstanceComplete={deselectItemInstanceAction} />
      break
    }
    case 'placeItemInstance': {
      tool = <PlaceItemInstanceTool itemInstance={selectedItemInstance} itemInstanceActionId={selectedItemInstanceActionId} placeItemInstanceComplete={deselectItemInstanceAction} />
      break
    }
  }

  return (
    <div className={styles.Inspector}>
      {
        selectedItemInstanceAction && tool
      }
      {
        !selectedItemInstanceAction && selectionState.selectedCharacterId &&
          <CharacterInspector />
      }
      {
        !selectedItemInstanceAction && selectedItemInstance &&
          <ItemInstanceInspector />
      }
    </div>
  )
}

export default Inspector
