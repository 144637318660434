import styles from './Action.module.css'

import React from 'react'
import { useGoodsCatalog } from '../../../contexts/GoodsCatalogContext'

function Action ({ action, performAction }) {
  const goodsCatalog = useGoodsCatalog()

  let result
  switch (action.name) {
    case 'receiveGoods': {
      const defaultGoodReq = action.requirements.find((req) => req.kind === 'reference' && req.name === 'good' && req.property === 'id' && req.schema.const)
      const defaultQuantityReq = action.requirements.find((req) => req.kind === 'input' && req.property === 'quantity' && req.schema.const)
      if (defaultGoodReq && defaultQuantityReq) {
        result = (
          <li>+{defaultQuantityReq.schema.const} {goodsCatalog[defaultGoodReq.schema.const].name}</li>
        )
      }
    }
  }

  return (
    <div className={styles.Action}>
      <button disabled={action.charges === 0} onClick={performAction}>{action.alias || action.name} {action.charges >= 0 && ('x' + action.charges)}</button>
      <ul>
        {result}
        {action.costs?.map((cost) => {
          return (
            <li key={cost.id}>-{cost.quantity} {goodsCatalog[cost.id].name}</li>
          )
        }).filter((_) => _)}
      </ul>
    </div>
  )
}

export default Action
