import React, { useContext, useEffect, useMemo, useReducer, Dispatch } from 'react'

import jwtDecode from 'jwt-decode'

interface AuthState {
  id?: string
  email?: string
  accessToken?: string

  idToken?: string
  refreshToken?: string
  lastFetch?: number
}

enum AuthActionType {
  IdTokenExpired = 'idTokenExpired',
  Authenticated = 'authenticated'
}

interface AuthActionIdTokenExpired {
  type: AuthActionType.IdTokenExpired
}

interface AuthActionAuthenticated {
  type: AuthActionType.Authenticated
  value: {
    refreshToken: string
    idToken: string
  }
}

type AuthAction = AuthActionAuthenticated | AuthActionIdTokenExpired

const {
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_APP_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_HOME_URL
} = process.env

function authReducer (state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionType.IdTokenExpired: {
      return {
        ...state,
        idToken: undefined
      }
    }
    case AuthActionType.Authenticated: {
      window.localStorage.setItem('oidc_refresh_token', action.value.refreshToken)
      window.localStorage.setItem('oidc_id_token', action.value.idToken)

      return {
        idToken: action.value.idToken,
        refreshToken: action.value.refreshToken,
        lastFetch: Date.now(),
        ...extractAuthInfo(action.value.idToken)
      }
    }
    default: { return state }
  }
}

interface OAuthJwtPayload {
  sub?: string
  email?: string
}

function extractAuthInfo (idToken: string): AuthState {
  const idTokenDecoded = jwtDecode<OAuthJwtPayload>(idToken)
  return {
    id: idTokenDecoded.sub,
    email: idTokenDecoded.email
  }
}

async function fetchTokens (authorizationCode: string, dispatch: Dispatch<AuthAction>): Promise<void> {
  const requestSearchParams = new URLSearchParams()
  requestSearchParams.append('grant_type', 'authorization_code')
  requestSearchParams.append('scope', 'email openid profile')
  requestSearchParams.append('redirect_uri', REACT_APP_APP_URL ?? '')
  requestSearchParams.append('client_id', REACT_APP_CLIENT_ID ?? '')
  requestSearchParams.append('code', authorizationCode)
  const response = await fetch(`https://${REACT_APP_AUTH_DOMAIN ?? ''}/oauth2/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: requestSearchParams.toString()
  })
  const tokenResponse = await response.json()

  if (tokenResponse?.error?.length > 0) {
    window.location.href = REACT_APP_HOME_URL ?? ''
  } else {
    dispatch({
      type: AuthActionType.Authenticated,
      value: {
        idToken: tokenResponse.id_token,
        refreshToken: tokenResponse.refresh_token
      }
    })
  }
}

async function refreshIdToken (refreshToken: string, dispatch: Dispatch<AuthAction>): Promise<void> {
  const requestSearchParams = new URLSearchParams()
  requestSearchParams.append('grant_type', 'refresh_token')
  requestSearchParams.append('scope', 'email openid profile')
  requestSearchParams.append('redirect_uri', REACT_APP_APP_URL ?? '')
  requestSearchParams.append('client_id', REACT_APP_CLIENT_ID ?? '')
  requestSearchParams.append('refresh_token', refreshToken)
  const response = await fetch(`https://${REACT_APP_AUTH_DOMAIN ?? ''}/oauth2/token`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: requestSearchParams.toString()
  })

  const tokenResponse = await response.json()
  if (tokenResponse?.error?.length > 0) {
    window.location.href = REACT_APP_HOME_URL ?? ''
  } else {
    window.localStorage.setItem('oidc_id_token', tokenResponse.id_token)
    dispatch({
      type: AuthActionType.Authenticated,
      value: {
        idToken: tokenResponse.id_token,
        refreshToken
      }
    })
  }
}

const searchParams = new URLSearchParams(window.location.search)
let didUseAuthorizationCode = false
let initialState: AuthState = searchParams.get('code') === null
  ? {
      idToken: window.localStorage.getItem('oidc_id_token') ?? undefined,
      refreshToken: window.localStorage.getItem('oidc_refresh_token') ?? undefined
    }
  : {}

if (initialState?.idToken !== undefined) {
  initialState = {
    ...initialState,
    ...extractAuthInfo(initialState.idToken)
  }
}

const AuthStateContext = React.createContext<undefined | AuthState>(undefined)
const AuthDispatchContext = React.createContext<undefined | Dispatch<AuthAction>>(undefined)

function AuthProvider ({ children }: { children: React.ReactNode }): JSX.Element {
  const [state, dispatch] = useReducer(authReducer, initialState)

  useEffect(() => {
    const authorizationCode = searchParams.get('code') ?? undefined
    // If we loaded and had a `code`, we should fetch a token (once) and not try to use idToken or refreshToken
    if (authorizationCode !== undefined) {
      // Even if this effect is re-triggered, we cannot reuse the authorizationCode
      if (!didUseAuthorizationCode) {
        didUseAuthorizationCode = true
        window.history.replaceState({}, '', '/')
        void fetchTokens(authorizationCode, dispatch)
      }
    } else if (state.idToken === undefined) {
      if (state.refreshToken !== undefined && (Date.now() - (state.lastFetch ?? 0)) > 15 * 1000) {
        void refreshIdToken(state.refreshToken, dispatch)
      } else {
        window.location.href = REACT_APP_HOME_URL ?? ''
      }
    }
  }, [state.idToken, state.refreshToken, state.lastFetch])

  const stateValue = useMemo(() => {
    return {
      id: state.id,
      email: state.email,
      accessToken: state.idToken
    }
  }, [state.id, state.email, state.idToken])

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={stateValue}>
        {state.idToken !== undefined && children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  )
}

function useAuthState (): AuthState {
  const context = useContext(AuthStateContext)
  if (context === undefined) {
    throw new Error('useAuthState must be called within a AuthProvider')
  }
  return context
}

function useAuthDispatch (): Dispatch<AuthAction> {
  const context = useContext(AuthDispatchContext)
  if (context === undefined) {
    throw new Error('useAuthDispatch must be called within a AuthProvider')
  }
  return context
}

export { AuthProvider, AuthActionType, useAuthState, useAuthDispatch }
