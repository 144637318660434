import styles from './UserPanel.module.css'

import React from 'react'
import { useAuthState } from '../../contexts/AuthContext'

const {
  REACT_APP_HOME_URL
} = process.env

function UserPanel () {
  const auth = useAuthState()

  function logout (e) {
    e.preventDefault()
    window.location = REACT_APP_HOME_URL
  }

  return (
    <div className={styles.UserPanel}>
      <h4>User: {auth.id}</h4>
      <span>Email: {auth.email}</span>
      <button onClick={logout}>Log Out</button>
    </div>
  )
}

export default UserPanel
