import styles from './PickUpItemInstanceTool.module.css'

import React, { useEffect } from 'react'

import { useRequestWithCompletion } from '../../../contexts/WebSocketContext'
import { TargetMode, useSelectionDispatch, useSelectionState } from '../../../contexts/SelectionContext'

function PickUpItemInstanceTool ({ itemInstance, itemInstanceActionId, pickUpItemInstanceComplete }) {
  const selectionState = useSelectionState()
  const selectionDispatch = useSelectionDispatch()
  const [isRequestComplete, makeRequest] = useRequestWithCompletion()

  useEffect(() => {
    if (isRequestComplete) {
      selectionDispatch({
        type: 'disableTargetMode'
      })
      pickUpItemInstanceComplete(true)
    }
  }, [isRequestComplete, selectionDispatch, pickUpItemInstanceComplete])

  function enableTargetMode (e) {
    e.preventDefault()
    selectionDispatch({
      type: 'enableItemInstanceZoneTargetMode'
    })
  }

  function pickUpItemInstance (e) {
    e.preventDefault()
    makeRequest({
      action: 'pickUpItemInstance',
      via: {
        id: itemInstance.SK,
        contextId: itemInstance.PK,
        actionId: itemInstanceActionId
      },
      references: {
        itemInstance: {
          id: selectionState.targetedItemInstance.SK
        },
        zone: {
          id: selectionState.targetedItemInstance.PK
        }
      }
    })
  }

  function dismiss (e) {
    e.preventDefault()
    selectionDispatch({
      type: 'disableTargetMode'
    })
    pickUpItemInstanceComplete(false)
  }

  return (
    <div className={styles.PickUpItemInstanceTool}>
      {selectionState.targetMode === TargetMode.DISABLED &&
        <div>
          <button onClick={enableTargetMode}>Pick Up Another Item</button>
        </div>}
      {selectionState.targetMode === TargetMode.ITEM_INSTANCE_ZONE &&
        <div>
          Selection: {selectionState.targetedItemInstance && selectionState.targetedItemInstance.name}
          <br />
          <button disabled={!selectionState.targetedItemInstance} onClick={pickUpItemInstance}>Pick Up</button>
        </div>}
      <button onClick={dismiss}>Cancel</button>
    </div>
  )
}

export default PickUpItemInstanceTool
