import styles from './DialogBuilder.module.css'

import React from 'react'

function DialogBuilder ({ dialogId, dialog, allDialogs, dialogsDispatch }) {
  function setHiddenDialog (e) {
    dialogsDispatch({
      type: 'setHiddenDialog',
      payload: {
        dialogId,
        value: !e.target.checked
      }
    })
  }

  function setDialogPrompt (e) {
    dialogsDispatch({
      type: 'setDialogPrompt',
      payload: {
        dialogId,
        value: e.target.value
      }
    })
  }

  function setDialogText (e) {
    dialogsDispatch({
      type: 'setDialogText',
      payload: {
        dialogId,
        value: e.target.value
      }
    })
  }

  function addDialogResponse (e) {
    e.preventDefault()
    dialogsDispatch({
      type: 'addDialogResponse',
      payload: {
        dialogId
      }
    })
  }

  function removeDialogResponse (e, responseId) {
    e.preventDefault()
    dialogsDispatch({
      type: 'removeDialogResponse',
      payload: {
        dialogId,
        responseId
      }
    })
  }

  function setResponseText (e, responseId) {
    dialogsDispatch({
      type: 'setResponseText',
      payload: {
        dialogId,
        responseId,
        value: e.target.value
      }
    })
  }

  function selectNextDialog (e, responseId) {
    dialogsDispatch({
      type: 'selectResponseNextDialog',
      payload: {
        dialogId,
        responseId,
        value: e.target.value
      }
    })
  }

  function removeDialog (e) {
    e.preventDefault()
    dialogsDispatch({ type: 'removeDialog', payload: dialogId })
  }

  return (
    <div className={styles.DialogBuilder}>
      <div>
        <button onClick={removeDialog}>-</button>
        <input type='checkbox' checked={!dialog.hidden} onChange={setHiddenDialog} />
        <label>Top Level Dialog</label>
        {!dialog.hidden && (
          <div>
            <label>Player Prompt</label>
            <input type='text' value={dialog.prompt} onChange={setDialogPrompt} />
          </div>
        )}
      </div>
      <div>
        <label>NPC Text</label>
        <input type='text' value={dialog.text.template} onChange={setDialogText} />
      </div>
      <div>
        <button onClick={addDialogResponse}>+</button>
        <label>Responses</label>
      </div>
      {Object.entries(dialog.responses).map(([responseId, response]) => {
        return (
          <div className={styles.Response} key={responseId}>
            <button onClick={(e) => removeDialogResponse(e, responseId)}>-</button>
            <label>Player Response</label>
            <input type='text' value={response.text.template} onChange={(e) => setResponseText(e, responseId)} />
            <div>
              <label>Next Dialog</label>
              <select value={response.dialog} onChange={(e) => selectNextDialog(e, responseId)}>
                <option value=''>None</option>
                {Object.entries(allDialogs)
                  .filter(([someDialogId, _]) => someDialogId !== dialogId)
                  .map(([dialogId, dialog]) => {
                    return (
                      <option key={dialogId} value={dialogId}>{dialog.text.template.substring(0, 20)}</option>
                    )
                  })}
              </select>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default DialogBuilder
