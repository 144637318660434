import React, { useContext, useEffect, useMemo, useReducer, useState, Dispatch } from 'react'

import { useAuthState, useAuthDispatch, AuthActionType } from './AuthContext'

const {
  REACT_APP_GATEWAY_URL
} = process.env

interface WebSocketState {
  isConnected: boolean
  lastConnectionAttempt?: number
  webSocket?: WebSocket
}

enum WebSocketActionType {
  Close = 'close',
  Connect = 'connect',
  Open = 'open',
}

interface WebSocketActionClose {
  type: WebSocketActionType.Close
}

interface WebSocketActionConnect {
  type: WebSocketActionType.Connect
  payload: WebSocket
}

interface WebSocketActionOpen {
  type: WebSocketActionType.Open
}

type WebSocketAction = WebSocketActionClose | WebSocketActionConnect | WebSocketActionOpen

const WebSocketStateContext = React.createContext<undefined | WebSocketState>(undefined)
const WebSocketDispatchContext = React.createContext<undefined | Dispatch<WebSocketAction>>(undefined)

function webSocketReducer (state: WebSocketState, action: WebSocketAction): WebSocketState {
  switch (action.type) {
    case WebSocketActionType.Close: {
      return {
        ...state,
        webSocket: undefined,
        isConnected: false
      }
    }
    case WebSocketActionType.Connect: {
      return {
        ...state,
        webSocket: action.payload,
        lastConnectionAttempt: Date.now()
      }
    }
    case WebSocketActionType.Open: {
      return {
        ...state,
        isConnected: true
      }
    }
    default: { return state }
  }
}

function WebSocketProvider ({ children }: { children: React.ReactNode }): JSX.Element {
  const [state, dispatch] = useReducer(webSocketReducer, {
    isConnected: false,
    lastConnectionAttempt: 0
  })

  const auth = useAuthState()
  const authDispatch = useAuthDispatch()

  useEffect(() => {
    if (auth.accessToken === undefined) {
      return
    }
    const webSocket = new WebSocket(`${REACT_APP_GATEWAY_URL ?? ''}?access_token=${auth.accessToken}`)
    dispatch({ type: WebSocketActionType.Connect, payload: webSocket })

    const openListener = (): void => {
      dispatch({ type: WebSocketActionType.Open })
    }

    const closeListener = (): void => {
      dispatch({ type: WebSocketActionType.Close })
      authDispatch({ type: AuthActionType.IdTokenExpired })
    }

    webSocket.addEventListener('open', openListener)
    webSocket.addEventListener('close', closeListener)

    return () => {
      webSocket.removeEventListener('open', openListener)
      webSocket.removeEventListener('close', closeListener)

      if (webSocket.readyState === WebSocket.OPEN) {
        webSocket.close()
      }
    }
  }, [auth.accessToken, authDispatch])

  const stateValue = useMemo(() => {
    return {
      isConnected: state.isConnected,
      webSocket: state.webSocket
    }
  }, [state.isConnected, state.webSocket])
  return (
    <WebSocketDispatchContext.Provider value={dispatch}>
      <WebSocketStateContext.Provider value={stateValue}>
        {state.isConnected && children}
      </WebSocketStateContext.Provider>
    </WebSocketDispatchContext.Provider>
  )
}

function useWebSocket (): WebSocketState {
  const context = useContext(WebSocketStateContext)
  if (context === undefined) {
    throw new Error('useWebSocket must be called within a WebSocketProvider')
  }
  return context
}

function useRequestWithCompletion (): [boolean, (payload: Object) => void] {
  const [requestId, setRequestId] = useState<string>('')
  const [isRequestComplete, setIsRequestComplete] = useState<boolean>(false)
  const webSocket = useWebSocket()

  useEffect(() => {
    const listener = (message: MessageEvent<string>): void => {
      const data = JSON.parse(message.data)
      if (requestId !== '' && data?.requestId === requestId) {
        setIsRequestComplete(true)
      }
    }
    webSocket.webSocket?.addEventListener('message', listener)
    return () => {
      webSocket.webSocket?.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket, requestId])

  function makeRequest (payload: Object): void {
    const requestId = String(Math.floor(Math.random() * 1000000))
    setRequestId(requestId)
    webSocket.webSocket?.send(JSON.stringify({ ...payload, requestId }))
  }

  return [isRequestComplete, makeRequest]
}

export { WebSocketProvider, useWebSocket, useRequestWithCompletion }
