import styles from './PlaceItemInstanceTool.module.css'

import React, { useEffect } from 'react'

import { useRequestWithCompletion } from '../../../contexts/WebSocketContext'
import { TargetMode, useSelectionDispatch, useSelectionState } from '../../../contexts/SelectionContext'
import { useZoneState } from '../../../contexts/ZoneContext'

function PlaceItemInstanceTool ({ itemInstance, itemInstanceActionId, placeItemInstanceComplete }) {
  const selectionState = useSelectionState()
  const selectionDispatch = useSelectionDispatch()
  const zoneState = useZoneState()
  const [isRequestComplete, makeRequest] = useRequestWithCompletion()

  useEffect(() => {
    if (isRequestComplete) {
      selectionDispatch({
        type: 'disableTargetMode'
      })
      placeItemInstanceComplete(true)
    }
  }, [isRequestComplete, selectionDispatch, placeItemInstanceComplete])

  function enableTargetMode (e) {
    e.preventDefault()
    selectionDispatch({
      type: 'enableItemInstanceInventoryTargetMode'
    })
  }

  function placeItemInstance (e) {
    e.preventDefault()
    makeRequest({
      action: 'placeItemInstance',
      via: {
        id: itemInstance.SK,
        contextId: itemInstance.PK,
        actionId: itemInstanceActionId
      },
      references: {
        itemInstance: {
          id: selectionState.targetedItemInstance.SK
        },
        zone: {
          id: zoneState.zone.PK
        }
      }
    })
  }

  function dismiss (e) {
    e.preventDefault()
    selectionDispatch({
      type: 'disableTargetMode'
    })
    placeItemInstanceComplete(false)
  }

  return (
    <div className={styles.PlaceItemInstanceTool}>
      {selectionState.targetMode === TargetMode.DISABLED &&
        <div>
          <button onClick={enableTargetMode}>Place Another Item</button>
        </div>}
      {selectionState.targetMode === TargetMode.ITEM_INSTANCE_INVENTORY &&
        <div>
          Selection: {selectionState.targetedItemInstance && selectionState.targetedItemInstance.name}
          <br />
          <button disabled={!selectionState.targetedItemInstance} onClick={placeItemInstance}>Place</button>
        </div>}
      <button onClick={dismiss}>Cancel</button>
    </div>
  )
}

export default PlaceItemInstanceTool
