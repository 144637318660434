import styles from './CreateZoneTool.module.css'

import React, { useEffect, useState } from 'react'

import { useWebSocket } from '../../../contexts/WebSocketContext'

function CreateZoneTool ({ itemInstance, itemInstanceActionId, createZoneComplete }) {
  const webSocket = useWebSocket()
  const [zoneName, setZoneName] = useState('')

  useEffect(() => {
    const listener = (message) => {
      const data = JSON.parse(message.data)
      for (const update of (data.updates || [])) {
        switch (update.event) {
          case 'zoneCreated': {
            createZoneComplete(update.payload.zone)
            break
          }
          default: { break }
        }
      }
    }
    webSocket.webSocket.addEventListener('message', listener)
    return () => {
      webSocket.webSocket.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket, createZoneComplete])

  function createZone (e) {
    e.preventDefault()
    webSocket.webSocket.send(JSON.stringify({
      action: 'createZone',
      via: {
        id: itemInstance.SK,
        contextId: itemInstance.PK,
        actionId: itemInstanceActionId
      },
      inputs: {
        name: zoneName
      }
    }))
  }

  function dismiss (e) {
    e.preventDefault()
    createZoneComplete(false)
  }

  return (
    <div className={styles.CreateZoneTool}>
      Create a Zone
      <form>
        <div>
          <label>
            Name:
            <input type='text' value={zoneName} onChange={(e) => setZoneName(e.target.value)} />
          </label>
        </div>
        <br />
        <button onClick={createZone}>Create</button>
        <button onClick={dismiss}>Cancel</button>
      </form>
    </div>
  )
}

export default CreateZoneTool
