import styles from './EnterZoneTool.module.css'

import React, { useEffect, useState } from 'react'

import { useRequestWithCompletion } from '../../../contexts/WebSocketContext'
import ZoneSelector from '../../Generic/ZoneSelector/ZoneSelector'

function EnterZoneTool ({ itemInstance, itemInstanceActionId, enterZoneComplete }) {
  const [isRequestComplete, makeRequest] = useRequestWithCompletion()
  const [zoneId, setZoneId] = useState('')

  useEffect(() => {
    if (isRequestComplete) {
      enterZoneComplete(true)
    }
  }, [isRequestComplete, enterZoneComplete])

  function enterZone (e) {
    e.preventDefault()
    makeRequest({
      action: 'enterZone',
      via: {
        id: itemInstance.SK,
        contextId: itemInstance.PK,
        actionId: itemInstanceActionId
      },
      references: {
        zone: {
          id: zoneId
        }
      }
    })
  }

  function dismiss (e) {
    e.preventDefault()
    enterZoneComplete(false)
  }

  return (
    <div className={styles.EnterZoneTool}>
      Enter a Zone
      <form>
        <ZoneSelector selectedZoneId={zoneId} selectZoneId={setZoneId} />
        <br />
        <button onClick={enterZone}>Enter</button>
        <button onClick={dismiss}>Cancel</button>
      </form>
    </div>
  )
}

export default EnterZoneTool
