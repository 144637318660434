import styles from './CreateGoodTool.module.css'

import React, { useEffect, useState } from 'react'

import { useWebSocket } from '../../../contexts/WebSocketContext'

function CreateGoodTool ({ itemInstance, itemInstanceActionId, createGoodComplete }) {
  const webSocket = useWebSocket()
  const [goodName, setGoodName] = useState('')

  useEffect(() => {
    const listener = (message) => {
      const data = JSON.parse(message.data)
      for (const update of (data.updates || [])) {
        switch (update.event) {
          case 'goodCreated': {
            createGoodComplete(update.payload.good)
            break
          }
          default: { break }
        }
      }
    }
    webSocket.webSocket.addEventListener('message', listener)
    return () => {
      webSocket.webSocket.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket, createGoodComplete])

  function createGood (e) {
    e.preventDefault()
    webSocket.webSocket.send(JSON.stringify({
      action: 'createGood',
      via: {
        id: itemInstance.SK,
        contextId: itemInstance.PK,
        actionId: itemInstanceActionId
      },
      inputs: {
        name: goodName
      }
    }))
  }

  function dismiss (e) {
    e.preventDefault()
    createGoodComplete(false)
  }

  return (
    <div className={styles.CreateGoodTool}>
      Create a Good
      <form>
        <div>
          <label>
            Name:
            <input type='text' value={goodName} onChange={(e) => setGoodName(e.target.value)} />
          </label>
        </div>
        <br />
        <button onClick={createGood}>Create</button>
        <button onClick={dismiss}>Cancel</button>
      </form>
    </div>
  )
}

export default CreateGoodTool
