import styles from './ReferenceRequirementBuilder.module.css'

import React from 'react'
import GoodSelector from '../../Generic/GoodSelector/GoodSelector'
import ItemSelector from '../../Generic/ItemSelector/ItemSelector'
import ZoneSelector from '../../Generic/ZoneSelector/ZoneSelector'

function ReferenceRequirementBuilder ({ actionId, requirementIndex, requirement, itemInstanceDispatch }) {
  function selectorForRequirement (requirement) {
    switch (requirement.type) {
      case 'good': {
        return (
          <GoodSelector
            selectedGoodId={requirement.schema.const}
            selectGoodId={(goodId) => itemInstanceDispatch({
              type: 'setRequirementValue',
              payload: { actionId, requirementIndex, property: 'const', value: goodId }
            })}
          />
        )
      }
      case 'item': {
        return (
          <ItemSelector
            selectedItemId={requirement.value}
            selectItem={(item) => itemInstanceDispatch({
              type: 'setRequirementValue',
              payload: { actionId, requirementIndex, property: 'const', value: item.PK }
            })}
          />
        )
      }
      case 'zone': {
        return (
          <ZoneSelector
            selectedZoneId={requirement.schema.const}
            selectZoneId={(zoneId) => itemInstanceDispatch({
              type: 'setRequirementValue',
              payload: { actionId, requirementIndex, property: 'const', value: zoneId }
            })}
          />
        )
      }
      default: break
    }
  }

  return (
    <div className={styles.ReferenceRequirementBuilder}>
      Reference:
      {requirement.kind}
      {requirement.name}
      {requirement.property}
      {selectorForRequirement(requirement)}
    </div>
  )
}

export default ReferenceRequirementBuilder
