import React, { useContext, useEffect, useState } from 'react'
import { Action } from '../types'

import { useWebSocket } from './WebSocketContext'

interface ActionsCatalogState {
  [thingy: string]: undefined | Action
}

const ActionsCatalogContext = React.createContext<undefined | ActionsCatalogState>(undefined)

function ActionsCatalogProvider ({ children }: { children: React.ReactNode }): JSX.Element {
  const webSocket = useWebSocket()
  const [actionsCatalog, setActionsCatalog] = useState<ActionsCatalogState>({})

  useEffect(() => {
    const listener = (message: MessageEvent<string>): void => {
      const data = JSON.parse(message.data)
      for (const update of (data.updates?.length > 0 ? data.updates : [])) {
        switch (update.event) {
          case 'actionsReturned': {
            const payload: {
              actions: Action[]
            } = update.payload
            setActionsCatalog(Object.fromEntries(payload.actions.map((action) => {
              return [action.name, action]
            })))
            break
          }
          default: { break }
        }
      }
    }
    webSocket.webSocket?.addEventListener('message', listener)
    webSocket.webSocket?.send(JSON.stringify({
      action: 'searchActions'
    }))
    return () => {
      webSocket.webSocket?.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket])

  return (
    <ActionsCatalogContext.Provider value={actionsCatalog}>
      {children}
    </ActionsCatalogContext.Provider>
  )
}

function useActionsCatalog (): ActionsCatalogState {
  const context = useContext(ActionsCatalogContext)
  if (context === undefined) {
    throw new Error('useActionsCatalog must be called within a ActionsCatalogProvider')
  }
  return context
}

export { ActionsCatalogProvider, useActionsCatalog }
