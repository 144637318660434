import React, { useContext, useEffect, useState } from 'react'
import { Good } from '../types'

import { useWebSocket } from './WebSocketContext'

interface GoodsCatalogState {
  [goodName: string]: Good
}

const GoodsCatalogContext = React.createContext<undefined | GoodsCatalogState>(undefined)

function GoodsCatalogProvider ({ children }: { children: React.ReactNode }): JSX.Element {
  const webSocket = useWebSocket()
  const [goodsCatalog, setGoodsCatalog] = useState({})

  useEffect(() => {
    const listener = (message: MessageEvent): void => {
      const data = JSON.parse(message.data)
      for (const update of (data.updates ?? [])) {
        switch (update.event) {
          case 'goodsReturned': {
            setGoodsCatalog(Object.fromEntries(update.payload.goods.map((good: Good) => {
              return [good.PK, good]
            })))
            break
          }
          case 'goodCreated': {
            const good = update.payload.good
            setGoodsCatalog((goods) => {
              return {
                ...goods,
                [good.PK]: good
              }
            })
            break
          }
          default: { break }
        }
      }
    }
    webSocket.webSocket?.addEventListener('message', listener)
    webSocket.webSocket?.send(JSON.stringify({
      action: 'searchGoods'
    }))
    return () => {
      webSocket.webSocket?.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket])

  return (
    <GoodsCatalogContext.Provider value={goodsCatalog}>
      {children}
    </GoodsCatalogContext.Provider>
  )
}

function useGoodsCatalog (): GoodsCatalogState {
  const context = useContext(GoodsCatalogContext)
  if (context === undefined) {
    throw new Error('useGoodsCatalog must be called within a GoodsCatalogProvider')
  }
  return context
}

export { GoodsCatalogProvider, useGoodsCatalog }
