import React, { useContext, useEffect, Dispatch } from 'react'

import { useImmerReducer } from 'use-immer'

import { useWebSocket } from './WebSocketContext'
import { useAuthState } from './AuthContext'
import { Character, ItemInstance } from '../types'

interface CharacterState {
  isLoaded: boolean
  character?: Character
}

enum CharacterActionType {
  AddDialogsToItemInstance = 'addDialogsToItemInstance',
  AddGoods = 'addGoods',
  AddItemInstance = 'addItemInstance',
  UpdateCharacter = 'updateCharacter',
  RemoveCharacter = 'removeCharacter',
  RemoveItemInstance = 'removeItemInstance'
}
interface CharacterActionAddDialogsToItemInstance {
  type: CharacterActionType.AddDialogsToItemInstance
  payload: {
    dialogs: string[]
    itemInstanceId: string
  }
}

interface CharacterActionAddGoods {
  type: CharacterActionType.AddGoods
  payload: {
    goodId: string
    quantity: number
  }
}

interface CharacterActionAddItemInstance {
  type: CharacterActionType.AddItemInstance
  payload: {
    itemInstance: ItemInstance
  }
}

interface CharacterActionRemoveCharacter {
  type: CharacterActionType.RemoveCharacter
}

interface CharacterActionRemoveItemInstance {
  type: CharacterActionType.RemoveItemInstance
  payload: {
    itemInstanceId: string
  }
}

interface CharacterActionUpdateCharacter {
  type: CharacterActionType.UpdateCharacter
  payload: Character
}

type CharacterAction =
  CharacterActionAddDialogsToItemInstance |
  CharacterActionAddGoods |
  CharacterActionAddItemInstance |
  CharacterActionRemoveCharacter |
  CharacterActionRemoveItemInstance |
  CharacterActionUpdateCharacter

const CharacterStateContext = React.createContext<undefined | CharacterState>(undefined)
const CharacterDispatchContext = React.createContext<undefined | Dispatch<CharacterAction>>(undefined)

function characterReducer (draft: CharacterState, update: CharacterAction): CharacterState {
  switch (update.type) {
    case CharacterActionType.UpdateCharacter: {
      draft.character = update.payload
      draft.isLoaded = true
      return draft
    }
    case CharacterActionType.RemoveCharacter: {
      draft.character = undefined
      return draft
    }
    case CharacterActionType.AddGoods: {
      const goods = draft.character?.stuff.goods
      if (goods !== undefined) {
        goods[update.payload.goodId] = (goods[update.payload.goodId] ?? 0) + update.payload.quantity
      }
      return draft
    }
    case CharacterActionType.RemoveItemInstance: {
      if (draft.character !== undefined) {
        draft.character.things.items = draft.character.things.items?.filter((item) => item.SK !== update.payload.itemInstanceId) ?? []
      }
      return draft
    }
    case CharacterActionType.AddItemInstance: {
      const items = draft.character?.things.items?.filter((item) => item.SK !== update.payload.itemInstance.PK) ?? []
      items.push(update.payload.itemInstance)
      if (draft.character !== undefined) {
        draft.character.things.items = items
      }
      return draft
    }
    case CharacterActionType.AddDialogsToItemInstance: {
      const item = draft.character?.things.items?.find((item) => item.SK === update.payload.itemInstanceId)
      if (item !== undefined) {
        item.dialogs = update.payload.dialogs
      }
      return draft
    }
    default: { return draft }
  }
}

function CharacterProvider ({ children }: { children: React.ReactNode }): JSX.Element {
  const webSocket = useWebSocket()
  const authState = useAuthState()
  const [state, dispatch] = useImmerReducer<CharacterState, CharacterAction>(characterReducer, { isLoaded: false })

  useEffect(() => {
    const listener = (message: MessageEvent<string>): void => {
      const data = JSON.parse(message.data)
      for (const update of (data.updates ?? [])) {
        switch (update.event) {
          case 'userReturned': {
            const user = update.payload.user
            if (authState.id !== user.PK.split('#')[1]) {
              return
            }
            if (user.things.characters?.length > 0) {
              webSocket.webSocket?.send(JSON.stringify({ action: 'getCharacter' }))
            } else {
              webSocket.webSocket?.send(JSON.stringify({ action: 'createCharacter' }))
            }
            break
          }

          case 'characterCreated':
          case 'characterReturned': {
            const character = update.payload.character
            if (character.PK.indexOf(authState.id) === -1) {
              return
            }
            dispatch({
              type: CharacterActionType.UpdateCharacter,
              payload: character
            })
            break
          }

          case 'characterDeleted': {
            update.type = 'removeCharacter'
            dispatch(update)
            break
          }

          case 'goodsReceived': {
            update.type = 'addGoods'
            dispatch(update)
            break
          }

          case 'itemInstanceRemovedFromInventory': {
            update.type = 'removeItemInstance'
            dispatch(update)
            break
          }

          case 'itemInstanceAddedToInventory': {
            update.type = 'addItemInstance'
            dispatch(update)
            break
          }

          case 'dialogsAddedToItemInstance': {
            update.type = 'addDialogsToItemInstance'
            dispatch(update)
            break
          }

          default: { break }
        }
      }
    }
    webSocket.webSocket?.addEventListener('message', listener)
    webSocket.webSocket?.send(JSON.stringify({ action: 'getUser' }))
    return () => {
      webSocket.webSocket?.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket, dispatch, authState.id])

  return (
    <CharacterDispatchContext.Provider value={dispatch}>
      <CharacterStateContext.Provider value={state}>
        {children}
      </CharacterStateContext.Provider>
    </CharacterDispatchContext.Provider>
  )
}

function useCharacterState (): CharacterState {
  const context = useContext(CharacterStateContext)
  if (context === undefined) {
    throw new Error('useCharacterState must be called within a CharacterProvider')
  }
  return context
}

function useCharacterDispatch (): Dispatch<CharacterAction> {
  const context = useContext(CharacterDispatchContext)
  if (context === undefined) {
    throw new Error('useCharacterDispatch must be called within a CharacterProvider')
  }
  return context
}

export { CharacterProvider, useCharacterState, useCharacterDispatch }
