import styles from './CreateItemTool.module.css'

import React, { useEffect } from 'react'
import { useImmerReducer } from 'use-immer'

import { useRequestWithCompletion } from '../../../contexts/WebSocketContext'
import ActionBuilder from './ActionBuilder'

function itemReducer (draft, update) {
  switch (update.type) {
    case 'setName': {
      draft.name = update.payload
      return draft
    }
    case 'addAction': {
      const maxId = Object.keys(draft.actions).reduce((highest, current) => {
        current = parseInt(current)
        return current > highest ? current : highest
      }, 0)
      draft.actions[(maxId + 1).toString()] = {
        alias: '',
        name: '',
        charges: -1,
        costs: [],
        requirements: []
      }
      return draft
    }

    case 'removeAction': {
      delete draft.actions[update.payload]
      return draft
    }

    case 'setActionName': {
      draft.actions[update.payload.actionId].name = update.payload.name
      draft.actions[update.payload.actionId].requirements = []
      return draft
    }

    case 'setActionRequirements': {
      draft.actions[update.payload.actionId].requirements = update.payload.requirements
        .filter((requirement) => requirement.schema.type === 'string' || requirement.schema.type === 'integer')
        .map((requirement) => {
          requirement = JSON.parse(JSON.stringify(requirement))
          requirement.expectedBy = 'actionUse'
          if (requirement.schema.type === 'integer') {
            requirement.schema.const = 0
          }
          return requirement
        })
      return draft
    }

    case 'setActionProperty': {
      draft.actions[update.payload.actionId][update.payload.property] = update.payload.value
      return draft
    }

    case 'addCost': {
      draft.actions[update.payload].costs.push({
        type: 'good',
        id: '',
        quantity: 0
      })
      return draft
    }

    case 'removeCost': {
      const costs = [...draft.actions[update.payload.actionId].costs]
      costs.splice(update.payload.costIndex, 1)
      draft.actions[update.payload.actionId].costs = costs
      return draft
    }

    case 'setCostProperty': {
      draft.actions[update.payload.actionId].costs[update.payload.costIndex][update.payload.property] = update.payload.value
      return draft
    }

    case 'setRequirementProperty': {
      draft.actions[update.payload.actionId].requirements[update.payload.requirementIndex][update.payload.property] = update.payload.value
      return draft
    }

    case 'setRequirementSchemaProperty': {
      draft.actions[update.payload.actionId].requirements[update.payload.requirementIndex].schema[update.payload.property] = update.payload.value
      return draft
    }

    // case 'setActionValidationPropertyConstMatch': {
    //   const actions = JSON.parse(JSON.stringify(state.actions))
    //   if (update.payload.targetProperty) {
    //     actions[update.payload.actionId].validation.properties[update.payload.sourceProperty] = {
    //       const: {
    //         $data: `1/${update.payload.targetProperty}`
    //       }
    //     }
    //   } else {
    //     delete actions[update.payload.actionId].validation.properties[update.payload.sourceProperty]
    //   }
    //   return {
    //     ...state,
    //     actions
    //   }
    // }

    default: { return draft }
  }
}

function CreateItemTool ({ itemInstance, itemInstanceActionId, createItemComplete }) {
  const [isRequestComplete, makeRequest] = useRequestWithCompletion()

  const [itemState, itemDispatch] = useImmerReducer(itemReducer, { name: '', actions: {} })

  useEffect(() => {
    if (isRequestComplete) {
      createItemComplete(true)
    }
  }, [isRequestComplete, createItemComplete])

  function setName (e) {
    itemDispatch({ type: 'setName', payload: e.target.value })
  }

  function addAction (e) {
    e.preventDefault()
    itemDispatch({ type: 'addAction' })
  }

  function createItem (e) {
    e.preventDefault()
    const item = JSON.parse(JSON.stringify(itemState))
    for (const entry of Object.entries(item.actions)) {
      const action = entry[1]
      if (!action.alias) {
        delete action.alias
      }
      if (action.charges === -1) {
        delete action.charges
      }
      if (action.costs.length === 0) {
        delete action.costs
      }
      action.requirements = action.requirements
        ?.filter((requirement) => requirement.expectedBy !== 'actionUse')

      action.requirements.forEach((requirement) => {
        delete requirement.expectedBy
      })
      if (!action.requirements?.length) {
        delete action.requirements
      }
    }
    makeRequest({
      action: 'createItem',
      via: {
        id: itemInstance.SK,
        contextId: itemInstance.PK,
        actionId: itemInstanceActionId
      },
      inputs: {
        item
      }
    })
  }

  function dismiss (e) {
    e.preventDefault()
    createItemComplete(false)
  }

  return (
    <div className={styles.CreateItemTool}>
      Create an Item
      <form>
        <div>
          <label>
            Name:
            <input type='text' value={itemState.name} onChange={setName} />
          </label>
        </div>
        <button onClick={addAction}>+</button>
        Actions
        {
          Object.entries(itemState.actions).map((entry) => {
            return (
              <ActionBuilder
                key={entry[0]}
                action={entry[1]}
                actionId={entry[0]}
                itemDispatch={itemDispatch}
              />
            )
          })
        }
        <br />
        <button onClick={createItem}>Create</button>
        <button onClick={dismiss}>Cancel</button>
      </form>
    </div>
  )
}

export default CreateItemTool
