import styles from './InputRequirementBuilder.module.css'

import React from 'react'

function InputRequirementBuilder ({ requirement, itemInstanceDispatch }) {
  return (
    <div className={styles.InputRequirementBuilder}>
      Input:
      {requirement.kind}
      {requirement.property}
      {JSON.stringify(requirement.schema ?? {})}
    </div>
  )
}

export default InputRequirementBuilder
