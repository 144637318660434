import styles from './CharacterPanel.module.css'

import React, { useState } from 'react'
import { useWebSocket } from '../../contexts/WebSocketContext'

import { useGoodsCatalog } from '../../contexts/GoodsCatalogContext'
import { useSelectionDispatch } from '../../contexts/SelectionContext'
import { useCharacterState } from '../../contexts/CharacterContext'

function CharacterPanel () {
  const webSocket = useWebSocket()
  const characterState = useCharacterState()
  const [offerId, setOfferId] = useState('')
  const goodsCatalog = useGoodsCatalog()
  const selectionDispatch = useSelectionDispatch()

  function createCharacter (e) {
    e.preventDefault()

    webSocket.webSocket.send(JSON.stringify({ action: 'createCharacter' }))
  }

  function deleteCharacter (e) {
    e.preventDefault()

    webSocket.webSocket.send(JSON.stringify({ action: 'deleteCharacter' }))
  }

  function visitCharacter (e) {
    e.preventDefault()

    webSocket.webSocket.send(JSON.stringify({
      action: 'observeZone',
      references: {
        zone: {
          id: characterState.character.contexts.zones[0].PK
        }
      }
    }))
  }

  function characterClicked (e) {
    e.preventDefault()
    selectionDispatch({
      type: 'selectCharacter',
      payload: characterState.character.PK
    })
  }

  function itemInstanceClicked (e, itemInstance) {
    e.preventDefault()
    selectionDispatch({
      type: 'selectItemInstance',
      payload: itemInstance
    })
  }

  function claimOffer (e) {
    e.preventDefault()
    webSocket.webSocket.send(JSON.stringify({
      action: 'claimOffer',
      references: {
        offer: {
          id: offerId
        }
      }
    }))
    setOfferId('')
  }

  return (
    <div className={styles.CharacterPanel}>
      {characterState.character &&
        <>
          <h4>Your Character: {characterState.character.PK}</h4>
          <button onClick={characterClicked}>Inspect</button>
          <button onClick={deleteCharacter}>Delete</button>
          <button onClick={visitCharacter}>Visit</button>
          <label>
            <button onClick={claimOffer}>Claim Offer</button>
            <input type='text' value={offerId} onChange={(e) => setOfferId(e.target.value)} />
          </label>
          <ul>
            {Object.entries(characterState.character.stuff.goods).map((goodEntry) => {
              const goodId = goodEntry[0]
              const goodQuantity = goodEntry[1]
              return goodsCatalog[goodId]
                ? (
                  <li key={goodId}>{goodsCatalog[goodId]?.name || goodId}: {goodQuantity}</li>
                  )
                : null
            }).filter((i) => i)}
          </ul>
          <ul>
            {characterState.character.things.items?.map((itemInstance) => {
              return (
                <li key={itemInstance.SK}><button onClick={(e) => itemInstanceClicked(e, itemInstance)}>{itemInstance.name}</button></li>
              )
            })}
          </ul>
        </>}
      {characterState.isLoaded && !characterState.character &&
        <>
          <button onClick={createCharacter}>Create Character</button>
        </>}
    </div>
  )
}

export default CharacterPanel
