import { Action, ItemInstance, Zone } from '../types'

function payloadForAction (actionId: string, action: Action, itemInstance: ItemInstance, zone: Zone): undefined | Object {
  switch (action.name) {
    case 'createItemInstance': {
      const defaultItemId = action.requirements?.find((requirement) => {
        return requirement.kind === 'reference' && requirement.name === 'item' && requirement.property === 'id'
      })?.schema?.const
      if (defaultItemId !== undefined) {
        return {
          action: action.name,
          references: {
            item: {
              id: defaultItemId
            }
          },
          via: {
            id: itemInstance.SK,
            contextId: itemInstance.PK,
            actionId
          }
        }
      }
      break
    }
    case 'enterZone': {
      const defaultZoneId = action.requirements?.find((requirement) => {
        return requirement.kind === 'reference' && requirement.name === 'zone' && requirement.property === 'id'
      })?.schema?.const
      if (defaultZoneId !== undefined) {
        return {
          action: action.name,
          references: {
            zone: {
              id: defaultZoneId
            }
          },
          via: {
            id: itemInstance.SK,
            contextId: itemInstance.PK,
            actionId
          }
        }
      }
      break
    }
    case 'pickUpItemInstance': {
      const defaultItemInstanceId = action.requirements?.find((requirement) => {
        return requirement.kind === 'reference' && requirement.name === 'itemInstance' && requirement.property === 'id'
      })?.schema?.const
      if (itemInstance.PK.indexOf('zone#') === 0 && defaultItemInstanceId !== undefined) {
        return {
          action: action.name,
          references: {
            zone: {
              id: itemInstance.PK
            },
            itemInstance: {
              id: defaultItemInstanceId
            }
          },
          via: {
            id: itemInstance.SK,
            contextId: itemInstance.PK,
            actionId
          }
        }
      }
      break
    }
    case 'placeItemInstance': {
      const defaultItemInstanceId = action.requirements?.find((requirement) => {
        return requirement.kind === 'reference' && requirement.name === 'itemInstance' && requirement.property === 'id'
      })?.schema?.const
      if (itemInstance.PK.indexOf('character#') === 0 && defaultItemInstanceId !== undefined) {
        return {
          action: action.name,
          references: {
            zone: {
              id: zone.PK
            },
            itemInstance: {
              id: defaultItemInstanceId
            }
          },
          via: {
            id: itemInstance.SK,
            contextId: itemInstance.PK,
            actionId
          }
        }
      }
      break
    }
    case 'receiveGoods': {
      const defaultGoodId = action.requirements?.find((requirement) => {
        return requirement.kind === 'reference' && requirement.name === 'good' && requirement.property === 'id'
      })?.schema?.const
      const defaultQuantity = action.requirements?.find((requirement) => {
        return requirement.kind === 'input' && requirement.property === 'quantity'
      })?.schema?.const
      if (defaultGoodId !== undefined && typeof defaultQuantity === 'number' && defaultQuantity > 0) {
        return {
          action: action.name,
          references: {
            good: {
              id: defaultGoodId
            }
          },
          via: {
            id: itemInstance.SK,
            contextId: itemInstance.PK,
            actionId
          },
          inputs: {
            quantity: defaultQuantity
          }
        }
      }
      break
    }
  }
}

export default payloadForAction
