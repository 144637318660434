import styles from './ZoneSelector.module.css'

import React, { useEffect, useState } from 'react'
import { useWebSocket } from '../../../contexts/WebSocketContext'
import { useSelectionState } from '../../../contexts/SelectionContext'

function ZoneSelector ({ selectedZoneId, selectZoneId }) {
  const webSocket = useWebSocket()
  const [zones, setZones] = useState([])
  const [zoneSearchName, setZoneSearchName] = useState('')
  const selectionState = useSelectionState()

  useEffect(() => {
    const listener = (message) => {
      const data = JSON.parse(message.data)
      for (const update of (data.updates || [])) {
        switch (update.event) {
          case 'zonesReturned': {
            setZones(update.payload.zones)
            break
          }
          default: { break }
        }
      }
    }
    webSocket.webSocket.addEventListener('message', listener)
    return () => {
      webSocket.webSocket.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket])

  function searchZones (e) {
    e.preventDefault()
    const searchZonesActionEntry = Object.entries(selectionState.selectedItemInstance.actions).find((entry) => {
      return entry[1].name === 'searchZones'
    })
    if (!searchZonesActionEntry) {
      alert("You can't search for zones")
      return
    }
    const request = {
      action: 'searchZones',
      inputs: {},
      via: {
        id: selectionState.selectedItemInstance.SK,
        contextId: selectionState.selectedItemInstance.PK,
        actionId: searchZonesActionEntry[0]
      }
    }
    if (zoneSearchName) {
      request.inputs.name = zoneSearchName
    }
    webSocket.webSocket.send(JSON.stringify(request))
  }

  return (
    <div className={styles.ZoneSelector}>
      <div>
        <label>
          Search for a zone:
          <input type='text' value={zoneSearchName} onChange={(e) => setZoneSearchName(e.target.value)} />
          <button onClick={searchZones}>Search</button>
        </label>
      </div>
      Zones:
      <select value={selectedZoneId} onChange={(e) => selectZoneId(e.target.value)}>
        <option value=''>{zones.length || '0'} Zones</option>
        {zones.map((zone) => {
          return (
            <option key={zone.PK} value={zone.PK}>{zone.name}</option>
          )
        })}
      </select>
    </div>
  )
}

export default ZoneSelector
