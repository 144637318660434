import React from 'react'

import styles from './ZoneWindow.module.css'

import { useSelectionDispatch } from '../../contexts/SelectionContext'
import { useZoneState } from '../../contexts/ZoneContext'

function ZoneWindow () {
  const zoneState = useZoneState()
  const selectionDispatch = useSelectionDispatch()

  function characterClicked (e, character) {
    e.preventDefault()
    selectionDispatch({
      type: 'selectCharacter',
      payload: character.SK
    })
  }

  function itemInstanceClicked (e, itemInstance) {
    e.preventDefault()
    selectionDispatch({
      type: 'selectItemInstance',
      payload: itemInstance
    })
  }

  return (
    <div className={styles.Zone}>
      {zoneState.zone &&
        <div>
          <h2>Zone: {zoneState.zone.name}</h2>
          <h3>Characters</h3>
          <ul>
            {zoneState.zone.things.characters?.map((character) => {
              return (
                <li key={character.SK}>
                  <button onClick={(e) => characterClicked(e, character)}>{character.SK}</button>
                </li>
              )
            })}
          </ul>
          <h3>Items</h3>
          <ul>
            {zoneState.zone.things.items?.map((itemInstance) => {
              return (
                <li key={itemInstance.SK}>
                  <button onClick={(e) => itemInstanceClicked(e, itemInstance)}>{itemInstance.name}</button>
                </li>
              )
            })}
          </ul>
        </div>}
    </div>
  )
}

export default ZoneWindow
