import styles from './Game.module.css'

import React from 'react'

import CharacterPanel from '../CharacterPanel/CharacterPanel'
import Chat from '../Chat/Chat'
import Inspector from '../Inspector/Inspector'
import UserPanel from '../UserPanel/UserPanel'
import ZoneWindow from '../ZoneWindow/ZoneWindow'
import { GoodsCatalogProvider } from '../../contexts/GoodsCatalogContext'
import { ActionsCatalogProvider } from '../../contexts/ActionsCatalogContext'
import { SelectionProvider } from '../../contexts/SelectionContext'
import { ZoneProvider } from '../../contexts/ZoneContext'
import { CharacterProvider } from '../../contexts/CharacterContext'

function Game () {
  return (
    <GoodsCatalogProvider>
      <ActionsCatalogProvider>
        <CharacterProvider>
          <ZoneProvider>
            <SelectionProvider>
              <div className={styles.Game}>
                <div className={styles.Main}>
                  <ZoneWindow />
                  <Chat />
                </div>
                <div className={styles.SideBar}>
                  <Inspector />
                  <CharacterPanel />
                  <UserPanel />
                </div>
              </div>
            </SelectionProvider>
          </ZoneProvider>
        </CharacterProvider>
      </ActionsCatalogProvider>
    </GoodsCatalogProvider>
  )
}

export default Game
