import React, { Dispatch, useContext, useEffect } from 'react'

import { useImmerReducer } from 'use-immer'

import { useWebSocket } from './WebSocketContext'
import { useAuthState } from './AuthContext'
import { Character, ItemInstance, Zone } from '../types'

interface ZoneState {
  zone?: Zone
}

enum ZoneActionType {
  AddCharacter = 'addCharacter',
  AddItemInstance = 'addItemInstance',
  RemoveCharacter = 'removeCharacter',
  RemoveItemInstance = 'removeItemInstance',
  SetZone = 'setZone',
  UnsetZone = 'unsetZone'
}

interface ZoneActionAddCharacter {
  type: ZoneActionType.AddCharacter
  payload: Character
}
interface ZoneActionAddItemInstance {
  type: ZoneActionType.AddItemInstance
  payload: ItemInstance
}
interface ZoneActionRemoveCharacter {
  type: ZoneActionType.RemoveCharacter
  payload: string
}
interface ZoneActionRemoveItemInstance {
  type: ZoneActionType.RemoveItemInstance
  payload: string
}
interface ZoneActionSetZone {
  type: ZoneActionType.SetZone
  payload: Zone
}
interface ZoneActionUnsetZone {
  type: ZoneActionType.UnsetZone
}

type ZoneAction =
  ZoneActionAddCharacter |
  ZoneActionAddItemInstance |
  ZoneActionRemoveCharacter |
  ZoneActionRemoveItemInstance |
  ZoneActionSetZone |
  ZoneActionUnsetZone

const ZoneStateContext = React.createContext<undefined | ZoneState>(undefined)
const ZoneDispatchContext = React.createContext<undefined | Dispatch<ZoneAction>>(undefined)

function zoneReducer (draft: ZoneState, update: ZoneAction): ZoneState {
  if (draft.zone === undefined) {
    switch (update.type) {
      case ZoneActionType.SetZone: {
        draft.zone = update.payload
        return draft
      }
      case ZoneActionType.UnsetZone: {
        draft.zone = undefined
        return draft
      }
    }
  } else {
    switch (update.type) {
      case ZoneActionType.AddCharacter: {
        if (draft.zone !== undefined) {
          const characters = draft.zone.things.characters?.filter((character) => character.SK !== update.payload.SK) ?? []
          characters.push(update.payload)
          draft.zone.things.characters = characters
        }
        return draft
      }
      case ZoneActionType.AddItemInstance: {
        const items = draft.zone.things.items?.filter((item) => item.SK !== update.payload.SK) ?? []
        items.push(update.payload)
        draft.zone.things.items = items
        return draft
      }
      case ZoneActionType.RemoveCharacter: {
        draft.zone.things.characters = draft.zone.things.characters?.filter((character) => character.SK !== update.payload) ?? []
        return draft
      }
      case ZoneActionType.RemoveItemInstance: {
        draft.zone.things.items = draft.zone.things.items?.filter((item) => item.SK !== update.payload) ?? []
        return draft
      }
      default: { return draft }
    }
  }
  return draft
}

function ZoneProvider ({ children }: { children: React.ReactNode }): JSX.Element {
  const webSocket = useWebSocket()
  const authState = useAuthState()
  const [state, dispatch] = useImmerReducer<ZoneState, ZoneAction>(zoneReducer, {})

  const zoneId = state.zone?.PK

  useEffect(() => {
    const listener = (message: MessageEvent): void => {
      const data = JSON.parse(message.data)
      for (const update of (data.updates ?? [])) {
        switch (update.event) {
          case 'characterCreated':
          case 'characterReturned': {
            const character = update.payload.character
            const charactersZone = character.contexts.zones[0]
            if (character.PK.indexOf(authState.id) !== -1 && charactersZone.PK !== zoneId) {
              webSocket.webSocket?.send(JSON.stringify({
                action: 'observeZone',
                references: {
                  zone: {
                    id: charactersZone.PK
                  }
                }
              }))
            }
            break
          }
          case 'zoneReturned': {
            dispatch({
              type: ZoneActionType.SetZone,
              payload: update.payload.zone
            })
            break
          }
          case 'characterDeleted': {
            dispatch({ type: ZoneActionType.UnsetZone })
            break
          }
          case 'characterEnteredZone': {
            const character = update.payload.character
            if (character.PK.indexOf(authState.id) !== -1 && update.payload.zoneId !== zoneId) {
              dispatch({
                type: ZoneActionType.UnsetZone
              })
              webSocket.webSocket?.send(JSON.stringify({
                action: 'observeZone',
                references: {
                  zone: {
                    id: update.payload.zoneId
                  }
                }
              }))
            } else {
              dispatch({
                type: ZoneActionType.AddCharacter,
                payload: character
              })
            }
            break
          }
          case 'characterLeftZone': {
            dispatch({
              type: ZoneActionType.RemoveCharacter,
              payload: update.payload.characterId
            })
            break
          }
          case 'itemInstanceAddedToZone': {
            dispatch({
              type: ZoneActionType.AddItemInstance,
              payload: update.payload.itemInstance
            })
            break
          }
          case 'itemInstanceRemovedFromZone': {
            dispatch({
              type: ZoneActionType.RemoveItemInstance,
              payload: update.payload.itemInstanceId
            })
            break
          }
          default: { break }
        }
      }
    }
    webSocket.webSocket?.addEventListener('message', listener)
    return () => {
      webSocket.webSocket?.removeEventListener('message', listener)
    }
  }, [webSocket.webSocket, dispatch, authState.id, zoneId])

  return (
    <ZoneDispatchContext.Provider value={dispatch}>
      <ZoneStateContext.Provider value={state}>
        {children}
      </ZoneStateContext.Provider>
    </ZoneDispatchContext.Provider>
  )
}

function useZoneState (): ZoneState {
  const context = useContext(ZoneStateContext)
  if (context === undefined) {
    throw new Error('useZoneState must be called within a ZoneProvider')
  }
  return context
}

function useZoneDispatch (): Dispatch<ZoneAction> {
  const context = useContext(ZoneDispatchContext)
  if (context === undefined) {
    throw new Error('useZoneDispatch must be called within a ZoneProvider')
  }
  return context
}

export { ZoneProvider, useZoneState, useZoneDispatch }
